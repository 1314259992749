<template>
  <v-footer
    class="grey lighten-4"
    height="55"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
          <template v-for="(s, i) in social">
            <a
              :key="s"
              class="grey--text pa-1 ml-4 pa-md-0"
              href="#"
              v-text="s"
            />

            <v-responsive
              v-if="i < social.length - 1"
              :key="`divider-${s}`"
              class="mx-4 shrink hidden-sm-and-down"
              max-height="24"
            >
              <v-divider vertical />
            </v-responsive>
          </template>
        </div>
      </v-col>

      <v-col
        class="text-center text-md-right"
        cols="12"
        md="6"
      >
        <div class="body-2 font-weight-light">
          &copy; 2022, made for a better
          <v-icon size="20">
            mdi-finance
          </v-icon> by
          <a
            href="http://udaconsulting.com"
            class="body-2 font-weight-light grey--text darken pl-1"
            target="_blank"
          >
            UDA Consulting
          </a>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardFooter',

    data: () => ({
      social: [
        'Twitter',
        'Linkedin',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
